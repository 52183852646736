import React from 'react';

const useExternalScript = (scriptUrl: string) => {
  const [scriptLoaded, setScriptLoaded] = React.useState(false);

  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = scriptUrl;
    script.type = 'text/javascript';
    script.async = true;
    script.onload = () => setScriptLoaded(true);
    document.body.appendChild(script);
  }, []);

  return scriptLoaded;
};

export default useExternalScript;
