import React from 'react';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import MailingListSignUp from '../components/MailingListSignUp';

const MailingListPage = () => {
  return (
    <Layout>
      <SEO title="Join mailing list | React Freelancer Switzerland" />
      <main>
        <div className="container text-left pt-5">
          <h1 className="text-dark mb-3">Stay up to date</h1>
          <p>By joining this mailing list you will be notified about:</p>
          <ul>
            <li>Promotions and news</li>
            <li>New blog posts about React development</li>
            <li>Upcoming project releases</li>
          </ul>

          <MailingListSignUp />
        </div>
        <div className="overlay"></div>
      </main>
    </Layout>
  );
};

export default MailingListPage;
