import React from 'react';
import useExternalScript from '../hooks/UseExternalScript';

export default () => {
  const isActiveCampaignFormReady = useExternalScript(
    'https://floriangyger.activehosted.com/f/embed.php?id=17'
  );

  return (
    <>
      {!isActiveCampaignFormReady && <p>Please wait...</p>}
      <div className="_form_17" />
    </>
  );
};
